import React from 'react'
import styled from "styled-components";
import { colors } from "../styles/theme";
import SectionWrapper from '../components/SectionWrapper'

//import topImage from "../images/cyber-3400789_1920.jpg";
import SEO from '../components/seo'

const Top = styled.div`
  height: calc(100vh - 75px);
`;

const IndexPage = () => (
  <div>
    <Top></Top>
    <SectionWrapper backgroundColor={colors.white} fontColor={colors.dark}>
    </SectionWrapper>
  </div>
)

export default IndexPage